<template>
	<div class="admin container">
		<h3>Admin <button type="buttton" class="btn btn-primary float-right" @click="sync" :disabled="syncing">{{(syncing) ? "in progress..." : "synchronize with Google Sheet"}}</button></h3>
		<p><input type="search" class="form-control" placeholder="search..." v-model="search"></p>
		<b-table :items="datasets.data" :fields="fields" small :filter="search">
			<template #cell(unique_id)="data">
				<router-link :to="data.value">{{data.value}}</router-link>
			</template>
			<template #cell(h5ad)="data">
				<span v-if="data.value" class="text-success"><v-icon name="check-circle" /></span>
				<span v-if="!data.value" class="text-danger"><v-icon name="ban" /></span>
				<button type="button" class="btn btn-sm btn-link" @click="selectDataset(data.item.unique_id)">upload...</button>
			</template>
		</b-table>
		<b-modal id="uploadModal" ref="uploadModal" :hide-footer="true" :title="`upload data files for ${datasetId}`">
			<p class="text-center"><strong>Upload <code>.zip</code> archive: </strong> </p>
			<p class="text-center">
				<uploader :end-point="`${serverUrl}/file.php`"  :multipart="true" :multipartChunkSize="29715200" :headers="authheaders" @startUpload="startUpload" @chunkUploaded="chunkUploaded" @fileUploaded="doneUpload" :disabled="loading" >
				</uploader>
			</p>
			<div  v-if="loading">
				<b-progress :value="partNb" :max="partMax" animated></b-progress>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { serverURL } from '@/app_config'
import { HTTP } from '@/router/http'
import { mapGetters } from 'vuex'
import uploader from 'vuejs-uploader'
export default {
	name: 'admin',
	components: { uploader },
	data () {
		return {
			fields: ['unique_id', 'Curator', 'DOI', 'Data_status', 'h5ad'],
			datasetId: '',
			serverUrl: serverURL.replace('index.php/', ''),
			search: '',
			syncing: false,
			loading: false,
			partMax: 1,
			partNb: 0
		}
	},
	computed: {
		...mapGetters({
			datasets: 'scrnaseq_datasets',
			user: 'currentUser'
		}),
		authheaders () {
			return {
				Authorization: `Basic ${this.user.authdata}`
			}
		}

	},
	methods: {
		selectDataset (uniqueId) {
			this.datasetId = uniqueId
			this.$refs.uploadModal.show()
		},
		startUpload () {
			this.loading = true
		},
		chunkUploaded (response) {
			this.partNb++
			this.partMax = +response.totalParts + 1
		},
		doneUpload (response) {
			this.partNb++
			let filename = response.file.file.name
			if (filename) {
				HTTP.patch('admin/file/' + filename).then(res => {
					this.loading = false
					this.$snotify.success(res.data + ' uploaded successfully')
					this.$store.commit('SET_DATASET_H5AD', res.data)
					this.$refs.uploadModal.hide()
				}).catch(err => this.$snotify.error(err))
			}
		},
		sync () {
			this.syncing = true
			HTTP.patch('/admin/sync').then(() => {
				this.syncing = false
				this.$snotify.success('Synchronisation successfull')
			}).catch(err => {
				this.syncing = false
				this.$snotify.error(err)
			})
		}
	},
	mounted () {
		this.$store.dispatch('getScrnaseqDatasets')
	}
}
</script>
