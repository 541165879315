
<!-- <template src="./login.html"></template> -->
<template>
	<div class="login_container">
		<div class="container">
			<h4>Admin access</h4>

			<div class="card">
				<div class="card-header"> Login </div>
				<div class="card-block">

					<form v-on:submit.prevent="loginUser()">
						<div class="form-group row">
							<div class="col-3"><label class="float-right" for="username">Username</label> </div>
							<div class="col-6"> <input type="text"  name="username" id="username" v-model="user.username" class="form-control" autofocus></div>
						</div>
						<div class="form-group row">
							<div class="col-3"> <label class="float-right" for="username">Password</label> </div>
							<div class="col-6"><input type="password" name="password" id="password" v-model="user.password" class="form-control"></div>
						</div>

						<div class="form-group row justify-content-center">
							<button type="submit" class="btn btn-primary" :disabled='!user.username || !user.password'>Submit</button>
						</div>
					</form>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { siteTitle } from '@/app_config'


export default {
	name: 'login',
	data () {
		return {
			siteTitle: siteTitle,
			user: { username: '', password: '' }
		}
	},

	methods: {
		loginUser () {
			var vm = this
			vm.$store.dispatch('login', vm.user).then((user) => {
				vm.$snotify.success('logged in successfully')
				var nextPage = '/admin'
				vm.$router.push(nextPage)
			}).catch((err) => {
				if (!_.isEmpty(err)) vm.$snotify.error(err)
			})
		}
	},
	created () {
		this.$store.dispatch('logout')
	}
}
</script>

	<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login_container{
	margin-top:100px;
}
form{
	margin:20px 0;
}
.card{
	margin:20px 0;
}
</style>











